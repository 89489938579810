import React, { Component } from 'react';
import Player from 'react-background-video-player';

class BGVideoPlayer extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
    this.videoList = [
      '/BG-1',
      '/BG-1 Copy 01',
      '/BG-1 Copy 02',
      '/BG-1 Copy 03'
    ];
    this.videoAmount = this.videoList.length-1;
    this.videoID = Math.floor(Math.random() * this.videoAmount);
  }

  componentDidMount() {
    this.setState({
      isPlaying: !this.player.isPaused,
      isMuted: this.player.isMuted,
    });
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })
  };

  handleOnPlay = () => {
    this.setState({isPlaying: true});
  };

  handleOnPause = () => {
    this.setState({isPlaying: false});
  };

  handleTimeUpdate = (currentTime, progress, duration) => {
    this.setState({
      progress,
      currentTime,
      duration,
    });
  };

  handleOnMute = () => {
    this.setState({isMuted: true});
  };

  handleOnUnmute = () => {
    this.setState({isMuted: false});
  };

  togglePlay = () => {
    this.player.togglePlay();
  };

  toggleMute = () => {
    this.player.toggleMute();
  };

  render() {

    return (
      <>
          <Player
            ref={p => this.player = p}
            containerWidth={this.state.windowWidth}
            containerHeight={this.state.windowHeight}
            src={this.videoList[this.videoID]+'.mp4'}
            poster={this.videoList[this.videoID]+'.png'}
            onPlay={this.handleOnPlay}
            onPause={this.handleOnPause}
            onMute={this.handleOnMute}
            onUnmute={this.handleOnUnmute}
            onTimeUpdate={this.handleTimeUpdate}
            startTime={10}
            autoPlay={true}
            volume={0.5}
          />
      </>
    );
  }
}

export default BGVideoPlayer;
