import React from 'react';
import { Link } from "react-router-dom";
import SmokeBase from './components/SmokeBase';
import './scss/index.scss';

function Contact() {
  return(
    <>
      <div id="content">
        <h1>contact</h1>
        <div>
        </div>
        </div>
      <SmokeBase />

    </>
  );
};

export default Contact;
