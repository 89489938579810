import React, {Component} from "react";
import { Link } from "react-router-dom";
import './scss/index.scss';
import ig from './assets/ig.png';

//adding components
import SmokeBase from "./components/SmokeBase";
import BGVideoPlayer from "./components/BGVideoPlayer";

class Home extends Component {
  constructor() {
    super();
    this.state = { weekstart:'',
                   weekend:'',
                   days:[],
    };
    this.weekstart = '';
    this.weekend = '';
    this.bgColorArray = [
      '#fcb900',
      'rgb(67, 183, 122)',
      '#7bdcb5',
      'rgb(0, 50, 207)',
      '#8ed1fc',
      'rgb(233, 52, 52)',
      '#cf2e2e',
      '#f5522e',
      '#ff6900',
      '#9b51e0' ];
    this.selectBG = '';
  }


  async componentDidMount() {



  }

  render() {

    return (

      <>
        <div id="content">
          <div className="content-inner">
          <svg id="smoke-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1020">
          <g>
            <polygon class="st0" points="1453.2,545.29 1463.35,545.29 1463.35,572.9 1470.8,572.9 1470.8,545.29 1480.96,545.29
              1480.96,539.03 1453.2,539.03 	"/>
            <polygon class="st0" points="1503.35,562.32 1503.26,562.32 1495.33,539.03 1484.85,539.03 1484.85,572.9 1491.82,572.9
              1491.82,549.13 1491.92,549.13 1500.22,572.9 1505.96,572.9 1514.26,548.89 1514.36,548.89 1514.36,572.9 1521.33,572.9
              1521.33,539.03 1510.85,539.03 	"/>
            <path class="st0" d="M586.72,250.85c32.94,0,46.94-9.65,51.01-22.6c1.92-6.12,1.3-9.88-0.96-15.54c-1.81-4.94-1.52-9.42,0.77-16.72
              c1.4-4.48,3.77-9.89,6.88-16.95l26.68-59.34c5.85-12.95,10.09-17.89,15.02-17.89c14.79,0-6.54,50.86,8.47,50.86
              c11.21,0,29.7-26.84,39.08-56.75c5.76-18.37,3.18-27.31-8.24-27.31c-7.39,0-22.38,6.36-34.24,6.36h-49.5
              c-15.46,0-19.96-6.36-28.7-6.36c-14.79,0-37.61,27.79-46.99,57.69c-5.17,16.49-4.02,26.37,2.93,26.37c15,0,38.8-50.86,54.03-50.86
              c4.03,0,5.32,2.35,3.77,7.29c-1.33,4.24-3.55,9.89-8,19.78l-24.16,53.45c-6.96,15.07-12.77,23.55-21.25,29.9
              c-8.79,6.59-14.07,12.01-16.21,18.84C553.68,241.91,558.49,250.85,586.72,250.85z"/>
            <path class="st0" d="M740.35,124.41c-1.47,4.71-5.25,13.18-8.29,20.01l-16.59,37.91c-6.67,15.54-12.91,23.31-21.18,29.67
              c-8.86,6.83-14.14,12.25-16.28,19.07c-3.32,10.59,2.07,16.95,23.36,16.95h80.2c25.54,0,49.4-29.66,58.78-59.57
              c3.54-11.3,2.09-18.13-4.62-18.13c-14.79,0-41.8,45.44-66.44,45.44h-3.81c-8.29,0-12.57-4.94-9.77-13.89
              c1.55-4.95,3.85-10.13,5.48-13.19l2.82-6.12c3.41-6.6,8.4-10.36,13.55-10.36h3.13c5.15,0,6.44,4.47,6.16,9.65
              c0.02,4.94,1.08,7.29,4.44,7.29c9.64,0,25.59-24.49,30.98-41.67c3.03-9.65,2.48-15.78-3.79-15.78c-9.18,0-16.45,15.31-25.86,15.31
              h-3.36c-4.03,0-5.91-3.3-3.4-11.3c0.89-2.83,2.82-6.84,4.29-10.13l2.45-4.94c3.7-7.53,10.18-13.19,18.69-13.19h4.48
              c12.54,0,19.9,11.54,19.38,31.08c-0.13,6.83,1,8.95,4.36,8.95c8.73,0,24.99-26.14,30.08-42.38c5.17-16.49,2.72-30.14-16.31-30.14
              h-77.49c-19.94,0-33.13,7.77-37.48,21.66c-1.99,6.36-1.08,9.89,1.69,13.9C742.18,113.58,742.2,118.52,740.35,124.41z"/>
            <path class="st0" d="M881.27,143.48l-17.32,38.85c-6.73,15.07-12.76,23.55-21.24,29.9c-8.79,6.59-14.07,12.01-16.21,18.84
              c-3.4,10.83,1.42,19.77,27.41,19.77c28.9,0,42.68-9.65,46.74-22.6c1.92-6.12,1.53-9.88-0.74-15.54c-2.03-4.94-1.52-9.42,0.76-16.72
              c1.4-4.48,3.77-9.89,6.87-16.95l14.66-33.2l4.8,62.64c0.16,2.35,1.74,4.47,3.75,4.47c2.25,0,4.85-1.18,7.6-4.24l62.17-63.34
              l-16.73,36.97c-6.51,15.07-12.75,23.55-21.24,29.9c-8.79,6.59-13.84,12.01-15.98,18.84c-3.39,10.83,1.2,19.77,29.65,19.77
              c32.94,0,46.71-9.65,50.77-22.6c1.92-6.12,1.52-9.88-0.74-15.54c-2.03-4.94-1.53-9.42,0.76-16.72c1.4-4.48,3.76-9.89,6.87-16.95
              l19.84-44.03c5.99-13.42,10.75-18.6,17.97-23.78c8.34-5.89,13.17-9.89,15.75-18.13c3.17-10.13-0.15-18.13-14.49-18.13h-23.07
              c-13.66,0-23.42,6.83-37.18,21.43l-44.24,46.86l-1.99-50.86c-0.72-12.71-6.19-17.42-19.63-17.42h-25.3
              c-17.7,0-31.17,10.83-34.93,22.84c-1.77,5.66-1.83,9.42,1.1,14.36c1.95,3.06,3.23,6.12,1.54,11.54
              C887.94,127.94,884.31,136.65,881.27,143.48z"/>
            <path class="st0" d="M1089.01,143.72l-17.01,38.62c-6.51,15.07-12.75,23.55-21.23,29.9c-8.79,6.59-14.07,12.01-16.2,18.84
              c-3.39,10.83,1.42,19.77,29.65,19.77c32.94,0,46.94-9.65,50.99-22.6c1.91-6.12,1.52-9.88-0.97-15.54
              c-1.81-4.94-2.2-9.42-1.25-12.48c0.74-2.36,2-4.94,3.26-7.53l3.11-6.36c0.96-1.65,2.08-2.36,3.64-2.36c5.16,0,7.14,9.42,21.92,9.42
              c31.59,0,66.54-33.67,80.02-76.77c9.43-30.14-4.66-44.5-26.17-44.5c-9.63,0-19.47,2.12-32.07,7.29c-6.44-4.47-17.2-7.29-31.98-7.29
              c-20.6,0-35.5,10.36-39.77,24.01c-2.06,6.59-1.01,10.36,1.7,13.89c2.18,3.07,2.5,7.77,0.73,13.42
              C1095.38,129.82,1092.12,136.65,1089.01,143.72z M1130.82,160.43c1.1-3.53,3.18-8.01,4.87-12.01l14.65-33.2
              c3.7-8.24,9.56-15.54,15.17-15.54c7.84,0,9.67,9.18,5.02,24.01c-5.53,17.66-21.99,43.8-33.63,43.8
              C1131.52,167.5,1129.42,164.91,1130.82,160.43z"/>
            <path class="st0" d="M1232.78,143.72l-17.01,38.62c-6.51,15.07-12.75,23.55-21.23,29.9c-8.78,6.59-14.06,12.01-16.2,18.84
              c-3.39,10.83,2.09,16.95,23.37,16.95h71.69c21.29,0,41.42-21.43,51.66-54.15c4.86-15.54,3.19-22.37-4.65-22.37
              c-16.8,0-37.87,47.33-58.04,47.33h-2.68c-6.28,0-8.31-3.53-5.65-12c2.21-7.07,7.6-18.61,11.82-27.79l19.67-44.27
              c5.54-12.01,10.44-18.36,18.19-23.78c8.27-5.66,13.39-10.6,15.97-18.84c3.24-10.36-0.53-19.79-30.1-19.79
              c-32.93,0-46.7,10.36-50.97,24.02c-2.06,6.59-0.93,10.12,1.7,13.89c2.18,3.06,2.27,7.77,0.51,13.42
              C1238.85,130.06,1235.81,136.88,1232.78,143.72z"/>
            <path class="st0" d="M1312.27,231.08c-3.31,10.59,2.09,16.95,23.38,16.95h80.2c25.55,0,49.38-29.66,58.72-59.57
              c3.53-11.3,2.08-18.13-4.64-18.13c-14.79,0-41.76,45.44-66.4,45.44h-3.81c-8.29,0-12.57-4.94-9.78-13.89
              c1.55-4.95,3.84-10.13,5.47-13.19l2.82-6.12c3.41-6.6,8.39-10.36,13.54-10.36h3.13c5.15,0,6.44,4.47,6.17,9.65
              c0.02,4.94,1.09,7.29,4.44,7.29c9.64,0,25.57-24.49,30.94-41.67c3.02-9.65,2.47-15.78-3.8-15.78c-9.18,0-16.43,15.31-25.84,15.31
              h-3.36c-4.03,0-5.92-3.3-3.41-11.3c0.88-2.83,2.81-6.84,4.28-10.13l2.45-4.94c3.7-7.53,10.17-13.19,18.67-13.19h4.48
              c12.54,0,19.91,11.54,19.41,31.08c-0.12,6.83,1.01,8.95,4.37,8.95c8.73,0,24.97-26.14,30.04-42.38
              c5.15-16.49,2.7-30.14-16.34-30.14h-77.49c-19.94,0-33.12,7.77-37.46,21.66c-1.99,6.36-1.07,9.89,1.7,13.9
              c2.18,3.06,2.21,8.01,0.37,13.9c-1.47,4.71-5.24,13.18-8.28,20.01l-16.55,37.91c-6.65,15.54-12.89,23.31-21.15,29.67
              C1319.68,218.83,1314.4,224.25,1312.27,231.08z"/>
            <path class="st0" d="M1809.89,242.73c-62.92,0-117.9,7.67-170.84,7.67c-6.14,0-12.53,0.77-17.39,5.37
              c-10.74,9.72-53.2,63.68-53.45,76.98c0,0,0.26,11,13.3,11c14.58,0,54.73-11.25,76.21-11.25c8.18,0,13.56,1.54,13.81,6.14
              c-23.02,45.53-72.89,133.76-136.83,133.76c-5.63,0-11.51-0.77-17.39-2.3c-7.93,0-13.55,3.84-13.55,7.42c0,1.54,1.02,2.81,3.07,4.09
              c9.71,5.37,26.59,9.46,48.08,9.46c22.5,0,49.62-4.35,79.28-16.37c81.33-32.99,121.74-114.58,186.7-208.44
              c2.56-3.84,4.09-7.93,4.09-11.77C1824.97,247.84,1820.37,242.73,1809.89,242.73z"/>
            <g>
              <path class="st0" d="M634.59,615.87c0.45-9.29-9.52-22.15-15.91-24.8c-9.36-3.87-19.51-2.29-24.35-2.28
                c-5.6,0-13.33,0.62-12.49-2.72c0.61-2.43,6.56-3.87,12.63-3.71c9.49,0.23,15.31,0.16,23.04-2.45c5.03-1.7,12.54-6.46,15.11-14.06
                c5.01-14.83-6.48-21.12-32.59-23.32c-11.11-0.94-18.89-1.52-27.93-1.94c-4.44-0.2-17.76,2.14-18.89,7.78
                c-1.17,5.83,11.31,5.75,12.97,9.06c0.94,1.87-2.82,3.01-3.91,3.37c-4.13,1.38-10.7,0.46-12.79,5.33c-2.7,6.3,9.51,7.6,8.88,11.37
                c-1.17,7.01-18.67,5.07-19.64,12.93c-1.1,8.94,11.46,8.45,10.42,14.9c-1.42,8.79-15.47,5.29-19.74,12.4
                c-6.2,10.33,15.58,4.96,13.32,12.88c-0.62,2.17-9.93,5.68-12.08,6.4c-5.92,1.97-16.31,8.52-8.02,13.5
                c9.4,5.64,22.63,0.88,32.83,0.47c15.77-0.63,30.37-0.49,45.91-3.41c6.25-1.17,12.99-1.86,18.51-5.85
                c4.39-3.18,5.55-7.69,7.62-10.48C630.51,627.22,634.18,624.22,634.59,615.87z M585.34,559.59c0.79-1.52,0.33-3.7,2.03-4.36
                c1.72-0.66,4.97,0.17,6.97,0.11c5.71-0.19,9.32-0.21,15.34,0.78c2.2,0.36,6.79,0.1,6.83,2.82c0.02,2.16-2.58,4.29-4.51,4.55
                c-0.45,0.07-2.29,0.05-2.55,0.4c-0.8,1.05,0.28,2.75-0.97,3.61c-1.78,1.21-4.92,0.6-6.95,0.45c-5.92-0.45-11.71,2.15-17.44,0.05
                C581.3,566.98,584.27,561.64,585.34,559.59z M611.43,611.76c-0.62,0.6-1.17,1.45-1.9,1.9c-1.75,1.07-4.67,2.12-5.35,4.29
                c-0.4,1.28,0.58,1.74,1.65,2.14c2.2,0.82,6.14,2.22,3.76,5.06c-0.39,0.47-0.92,0.75-1.44,1.01c-0.61,0.29-1.41,0.6-2.07,0.7
                c-4.37,0.62-8.5,0.61-12.89,0.78c-6.33,0.26-12.7,1.01-19.01,1.24c-4.11,0.15-10.88,1.35-14.29-1.7c-3.83-3.43,1.15-7.28,4.18-8.6
                c0.96-0.42,2.01-0.34,2.98-0.82c1-0.5,1.68-1.17,2.4-2c1.66-1.9-0.85-2.75-1.57-4.28c-0.62-1.3,0-3.22,1.22-4.06
                c1.7-1.19,3.26-0.52,5.13-0.35c1.09,0.1,2.18-0.36,3.26-0.29c0.62,0.04,6.15-0.1,8.34-0.34c4.16-0.48,8.81-0.48,13-0.29
                c1.75,0.08,3.44,0.29,5.18,0.45c1.27,0.11,2.56,0.28,3.77,0.68c1.08,0.36,2.17,0.55,3.25,0.88
                C612.74,608.73,612.65,610.58,611.43,611.76z"/>
              <g>
                <path class="st0" d="M739.39,628.21c0.2-6.33,11.6-2.74,11.96-10.83c0.37-8.49-12.88-2.76-15.21-9.37
                  c-1.62-4.58,8.18-6.86,5.62-11.98c-1.8-3.59-11.58-2.69-12.42-6.85c-1.55-7.75,13.32-5.59,11.54-14.49
                  c-1.18-5.9-20.31-2.32-16.38-8.23c2.04-3.06,7.84-1.58,9.47-5.01c4.79-10.04-14.49-5.13-15.94-9.36
                  c-1.47-4.31,1.7-11.24-12.58-11.87c-13.08-0.58-8.9,7.38-10.87,8.36c-6.39,3.2-17.33,0.56-18.15,7.44
                  c-0.74,6.21,6.25,4.7,4.47,10.03c-1.95,5.85-15.11,2.88-17.27,8.46c-2.44,6.31,5.52,10.14,4.73,11.8
                  c-2.61,5.46-17.3,2.36-16.19,11.55c0.53,4.39,9.64,4.39,7.66,9.51c-2.4,6.2-18.19,2.75-17.09,11.73c0.64,5.24,8.55,5,9.44,9.44
                  c0.8,4-18.41,11.45-21.15,13.58c-1.54,1.2-5.55,3.98-4.06,6.57c2.42,4.24,9.6,5.06,13.9,4.39c10.92-1.7,22.07-1.61,33.1-2.96
                  c4.23-0.51,11.41,0.35,14.01-3.57c6.71-10.07-13.99-7.64-15.32-14.29c-0.63-3.12,13.9-3.63,16.94-8.21
                  c3.64-5.46-16.3-2.21-14.82-10.89c0.65-3.8,9.71-5.96,22.57-5.71c21.58,0.43,22.71,3.37,23.03,3.91c4.27,7.45-10.76,6-12.95,9.3
                  c-3.65,5.47,10.79,7.88,11.64,12.11c1.78,8.89-17.21,6.22-12.19,15c1.28,2.25,5.02,2.68,7.33,2.68c1.93,0,28.85-0.75,33.18-1.01
                  c4.4-0.28,8.9-1.99,8.84-6.08C756.15,636.7,739.2,634.42,739.39,628.21z M710.35,588.7c-5.53,0.27-11.06,0.39-16.62,0.37
                  c-1.85,0-3.88,0.07-5.69-0.26c-0.75-0.13-2.01-0.24-2.16-1.25c-0.19-1.28,2.72-2.01,3.44-2.31c2.83-1.18,5.08-1.75,5.27-5.22
                  c0.15-2.88-7.9-3.89-5.01-7.07c1.17-1.29,2.87-1.23,4.47-1.37c3.56-0.29,7.09-0.18,10.65-0.26c1.35-0.03,7.16,0.78,7.76,1.39
                  c3.15,3.24-0.47,5.11-3.91,6.12c-0.74,0.23-3.02,0.74-3.03,1.84c-0.01,1.01,1,1.62,1.8,2.06c2.04,1.11,5.18,2.12,7.45,2.52
                  c0.26,0.04,2.85,0.91,2.21,1.59C715.37,588.51,712.69,588.58,710.35,588.7z"/>
              </g>
              <path class="st0" d="M834.7,571.86c7.66-0.87,8.57-6.38,9.22-10.74c0.93-6.22-12.56-3.3-14.56-11.21
                c-2.25-8.86-12.87-7.54-20.66-6.66c-2.46,0.28-26.45,0.02-28.07-0.05c-3.75-0.16-28.85-1.43-30.85,4.04
                c-2.5,6.83,10.01,7.09,13.11,8.5c5.66,2.58-0.94,10,3.76,11.71c5.85,2.14,22.21-2.92,25.6,4.38c3.48,7.49-10.11,6.77-11.28,12.34
                c-1.41,6.65,15.56,7.23,14.32,13.07c-1.53,7.23-16.89,3.66-19.47,12.88c-1.82,6.51,15.28,4.61,13.4,9.74
                c-2.06,5.63-16.54,0.29-19.4,8.54c-2.49,7.16,15.24,2.58,13.29,7.88c-1.37,3.76-21.69,4.81-22.83,12.84
                c-0.83,5.86,13.62,2.65,19.85,2.42c8.07-0.29,9.9,3.65,16.63,0.53c5.73-2.66,7.75-19.23,8.94-24.88
                c3.63-17.14,4.53-37.53,12.33-52.46C821.26,568.53,832.56,572.11,834.7,571.86z"/>
              <path class="st0" d="M934.11,542.43c-1.83-2.45-14.35-0.48-16.56,0.23c-7.94,2.52-14.48,14.68-15.1,20.52
                c-0.53,5.08-2.23,9.97-3.32,14.92c-0.68,3.06-4.26,11.03-10.95,10.62c-5.48-0.34-10.04-6.92-9.04-11.35
                c1.86-8.23,4.08-17.61,5.78-24.78c0.33-1.4-0.24-11.35-9.37-10.98c-6.22,0.25-13.99-0.48-19.85,1.77
                c-2.05,0.79-4.28,1.8-4.84,4.19c-2.11,8.9,14.52,6.02,13.55,14.09c-0.3,2.5-3.34,2.8-5.29,3.19c-3.45,0.69-6.67,2.23-9.7,3.96
                c-1.57,0.91-3.55,1.77-3.32,3.83c0.44,3.97,8.63,4.85,11.45,6.31c1.13,0.59,3.48,1.62,3.07,3.31c-1.75,7.23-11.77,5.69-16.9,8.27
                c-2.37,1.19-5.82,3.56-5.56,6.68c0.29,3.6,10.02,5.95,12.95,7.47c1.11,0.57,3.57,2.65,3.21,4.15c-1.1,4.62-12.26,6.75-16.51,7.52
                c-2.86,0.51-7.54,1.72-7.59,5.43c-0.03,1.78,2.6,2.69,3.83,3.32c3.14,1.62,6.67,2.45,10.05,3.49c1.11,0.34,3.56,1.18,3.83,2.56
                c0.45,2.25-2.65,3.61-4.23,4.11c-2.39,0.76-4.95,0.79-7.39,1.34c-1.34,0.3-3.01,0.66-4.27,1.23c-4.09,1.86-13.51,11.23-7.72,14.93
                c1.6,1.02,3.75,0.49,5.54,0.38c4.38-0.28,8.64,0.36,13.06-0.12c6.54-0.73,19.17,3.28,24.04-3.23c2.22-2.97,2.49-7.54,3.04-11.05
                c1.41-8.96,1.92-13.56,3.53-22.47c0.81-4.48,4.26-15.51,13.21-15.32c9.16,0.2,11.28,9.2,9.74,16.2
                c-2.24,10.14-12.18,34.25-7.05,34.45c11.06,0.46,23.88,0.77,26.34,0.27c5.16-1.05,5.17-18.43,6.79-23.63
                c2.26-7.23,0.96-13.74,2.12-21.09C926.14,597.98,940.49,551,934.11,542.43z"/>
              <path class="st0" d="M1149.01,590.69c0.52-5.43-9.09-7.29-8-12.96c0.69-3.53,0.62-13.17-8.84-14.88c-7.91-1.43-0.6-6.61-5.5-12.76
                c-4.95-6.21-17.66-6.98-22.06-6.77c-14.3,0.69-15.25,10.38-20.52,12.26c-3.99,1.42-17.16,3.42-18.15,8.36
                c-0.58,2.88,2.3,5.63,1.19,8.63c-2.52,6.78-9.64,5.2-15.31,9.72c-4.67,3.72,0.46,9.4-2.24,12.71
                c-3.72,4.54-15.07,3.37-15.52,11.32c-0.45,7.91,9.09,10.86,9.3,14c0.32,4.76-6.52,7.89-0.95,12.46c2.72,2.23,11.45,2.58,12.66,5
                c1.56,3.12-1.63,8.64,3.75,8.12c2.92-0.29,7.58,0.44,8.87,1.96c1.73,2.05,4.32,5.29,8.67,5.18c8.94-0.23,8.16,0.21,18.6-0.27
                c5.47-0.25,3.28-8.01,7.1-11.03c3.01-2.39,10.76,0.16,12.32-4.51c1.33-3.99-0.99-7.4,3.27-11.19c3.79-3.38,9.9-0.93,11.71-6.32
                c1.54-4.58-0.91-14.06,5.19-15.05C1147.33,602.58,1148.45,596.52,1149.01,590.69z M1116.6,603.99c-3.01,4.63,1.71,8.66-0.74,10.66
                c-3.61,2.97-4.86,1.52-7.39,2.66c-2.49,1.13-1.28,4.02-2.8,5.67c-2.4,2.59-10.45,3.33-14.2,1.34c-1.99-1.07-2.85-3.52-4.62-4.15
                c-3.3-1.15-8.24,0.34-11-1.56c-3.4-2.33,0.88-5.91-1.11-8.78c-2.74-3.96-9.19-1.69-12.3-5.33c-1.08-1.26-0.36-3.43,0.3-4.64
                c2.83-5.14,8.04-4.03,12.17-7.25c1.96-1.54,1.67-4.57,1.08-6.63c-0.34-1.15-1.2-2.31-0.37-3.41c2.81-3.73,9.38-2.49,12.97-5.21
                c2.27-1.73-0.81-5.6,3.21-8.37c4.02-2.77,9.61-2.85,12.99-0.26c2.55,1.96-0.05,5.86,2.47,8.71c3.42,3.86,6.35,1.21,8.51,4.09
                c1.65,2.21-1.15,5,0.71,8.09c2.47,4.11,9.64,0.49,9.39,5.75C1125.65,600.03,1118.52,601.03,1116.6,603.99z"/>
              <path class="st0" d="M1248.46,556.65c0.65-2.78,3.33-8.13,1.6-10.8c-3.5-5.4-12.73-2.72-24.21-2.98
                c-8.01-0.18-12.75-0.01-13.7,3.85c-1.49,5.99,4.33,8.73,5.82,9.66c5.82,3.63,11.21,1.22,9.05,9.21
                c-4.21,15.58-15.99,71.21-33.55,71.22c-11.39,0-5.83-16.19-4.72-21.84c0.69-3.55,13.77-51.08,16.48-57.79
                c0.69-1.7-2.3-13.26-10.2-14.02c-5.95-0.57-9.42-0.29-14.36-0.44c-9.53-0.27-14.54,2.65-15.73,6.74
                c-2.73,9.35,16.12,9.37,16.45,15.25c0.2,3.47-4.43,4.35-6.94,4.81c-5.11,0.95-11.71,0.31-13.62,6.4
                c-3.14,10.02,10.94,10.3,8.61,17.62c-2.35,7.42-18.86-0.53-21.14,8.9c-1.43,5.91,5.45,7.24,10.24,8.35
                c3.66,0.85,6.34,4.18,5.38,7.03c-3.64,10.83-16.91,0.11-20.42,8.75c-2.75,6.76,8.07,6.43,14.3,7.8c1.01,0.22,4.02,1.38,3.47,3.72
                c-1.27,5.32-10.27,2.51-15.62,4.06c-3.8,1.1-7.86,5.19-3.96,8.67c4.76,4.23,43.94,1.88,59.71,1.54
                c23.22-0.51,29.57-30.93,35.53-52.34C1239.22,591.76,1246.5,565,1248.46,556.65z"/>
              <path class="st0" d="M1366.7,541.18c-5.97-0.06-8.97,5.56-8.44,8.58c1.52,8.62,11.87,8.17,11.53,14.12
                c-0.25,4.33-19.59,2.32-19.97,10.21c-0.36,7.61,18.67,3.99,17.72,11.05c-1.05,7.79-27.83,1.01-25.67,14.23
                c1.21,7.38,18.85,4.82,17.13,11.97c-2.24,9.28-16.36,0.13-19.85,11.02c-2.13,6.64,16.17,7.32,14.8,12.06
                c-1.94,6.73-19.17,0.23-19.89,11.82c-0.45,7.26,9.29,4.89,16.9,5.33c15.16,0.86,30.49-1.48,45.69-1.51
                c6.87-0.01,15.44,1.76,21.64-0.91c3.9-1.68,0.46-12.27,2.82-15.08c2.98-3.53,14.1,0.16,14.56-7.86
                c0.31-5.34-38.59-2.85-44.84-2.89c-6.07-0.03,0.7-5.13,0.58-9.13c-0.15-5.03-8.04-6.71-8.42-11.16
                c-1.6-18.77,42.03,2.06,42.91-13.44c0.3-5.33-10.53-4.41-10.63-5.46c-0.1-1.26,2.43-3.32,2.02-4.28
                c-2.25-5.22-14.19-4.1-18.8-4.62c-3.23-0.36-17.62-1.73-11.79-7.87c7.01-7.39,9.85-3.87,18.94-6.12c6.16-1.52,4.69-3.83,7-4.83
                c3.17-1.37,9.75,0.39,13.37-0.08c3.28-0.42,14.62-1.21,15.05-6.71C1442.08,536.69,1400.23,541.53,1366.7,541.18z"/>
              <g>
                <path class="st0" d="M1598.43,245.19c-1.02,0-2.06,0-3.33,0.26c0,0-18.39,4.6-36.04,4.6c-3.83,0-22.74-0.77-48.79-0.77
                  c-57.24,0-114.75,38.08-114.75,82.28c0,11.25,3.85,23,12.28,34.5c2.04,2.82,3.33,2.82,3.33,4.09c0,1.54-2.56,2.3-2.56,2.3
                  c-48.29,14.83-81.27,53.67-81.27,84.08c0,1.91,0.22,3.72,0.51,5.49c-25.58-33.29-41.65-82.18-41.65-96.59
                  c0-14.31,21.45-24.02,31.17-27.6c32.71-11.75,51.1-17.11,63.88-19.41c7.68-1.28,9.47-2.81,9.47-5.37
                  c0-12.53-25.05-61.33-35.02-61.33c-2.29,0-5.1,0.51-7.66,2.3c-27.34,20.18-94.55,83.56-117.04,93.78c-0.5,0-0.77-0.51-0.77-1.27
                  c0-3.84,22.74-42.43,22.74-61.59c0-14.06-9.7-19.42-19.93-19.42c-57.18,0-86.86,39.58-136.22,41.71
                  c1.12-4.08,1.85-8.09,1.85-11.94c0-22.75-20.95-40.38-74.1-40.38c-29.4,0-76.68,7.15-115.5,7.15c-16.62,0-17.89-1.27-32.46-1.53
                  c-8.7,0-14.32,5.11-18.66,9.97c-4.17,6.64-22.24,31.54-31.3,48.34c-10.67-10.16-25.42-21.57-31.72-21.57
                  c-13.04,0-89,98.97-97.18,98.97c-9.21,0-13.3-5.63-13.3-14.32c0-31.97,56.01-106.14,131.2-106.14c9.98,0,16.88,1.02,17.14,1.02
                  c3.58,0,6.39-4.6,6.39-7.42c0-1.02-0.51-1.79-1.28-2.05c-9.72-2.81-20.71-5.11-33.76-5.11c-153.96,0-242.7,130.69-242.7,196.16
                  c0,14.37,4.46,25.5,13.33,31.47c-4.89,2.15-9.57,3.29-13.36,3.29c-13.55,0-20.97-12.02-20.97-21.48
                  c0-45.53,52.94-142.2,68.54-160.61c1.79-2.05,2.56-4.61,2.56-7.42c0-6.39-4.35-13.55-11.25-13.55
                  c-28.64,0-87.98,61.89-94.37,61.89c-0.51,0-0.51-0.26-0.51-0.51c0-5.89,29.92-49.87,39.64-64.45c3.07-4.6,4.6-9.46,4.6-14.58
                  c0-9.72-12.02-40.41-29.41-40.41c-6.14,0-11.25,2.81-11.25,2.81c-22.5,10.49-109.71,110.48-116.11,110.48
                  c-1.02,0-1.02-1.28-1.02-1.28c12.27-13.3,50.63-64.19,50.63-94.88c0-14.06-7.93-24.04-30.43-24.04
                  c-58.82,0-88.49,41.94-140.41,41.94c-37.34,0-67.77-10.23-110.23-38.11c-4.09-2.81-8.18-3.84-12.28-3.84
                  c-9.97,0-67,20.97-74.42,30.94c-0.77,1.28-1.28,2.56-1.28,3.84c0,4.86,4.86,11.25,12.27,16.37
                  c36.57,24.81,70.59,34.02,102.05,34.02c75.95,0,155.75-62.41,173.65-62.41c5.37,0,5.89,4.86,5.89,13.56
                  c0,31.46-85.42,116.62-136.57,144.5c-8.7,4.6-10.23,7.93-10.23,9.72c0,21.23,32.99,48.59,57.54,48.59
                  c12.27,0,23.53-4.09,30.69-8.18c11.25-6.39,71.35-69.82,74.94-73.4c0.77-0.77,1.28-1.02,1.79-1.02c1.02,0,1.28,2.05,1.28,5.11
                  c0,0-32.74,43.48-40.66,54.48c-2.3,3.07-3.07,5.63-3.07,7.93c0,12.02,35.8,20.2,58.82,20.2c11,0,18.67-3.07,27.37-9.46
                  c13.81-10.23,57.03-65.22,58.31-67.26c1.02-1.02,3.58-2.56,5.11-2.56c1.02,0,1.54,0.51,1.54,1.79
                  c-0.77,5.63-27.37,56.52-27.37,56.52c-19.43,35.55-27.62,62.91-27.62,82.61c0,28.39,16.62,41.43,40.15,41.43
                  c49.01,0,95.05-44.11,117.83-78.09c15.97-1.72,37.5-11.11,65.06-31.1c0.77-0.77,1.28-1.02,1.79-1.02c1.54,0,2.3,2.81,2.3,2.81
                  c3.58,15.34,29.16,35.29,63.94,35.29c16.65,0,35.38-4.66,54.74-16.4c11.49,16.36,62.09,20.4,70.7,20.4
                  c11.76,0,20.95-7.16,29.9-23c0,0,29.38-56.73,30.65-59.54c0.52-1.28,1.79-1.53,3.08-1.53c1.27,0,2.56,0.25,3.06,0.76
                  c0.77,0.77,3.58,17.63,3.58,17.63c6.4,20.77,8.7,78.64,30.14,105.3c-1.59,0.74-3.05,1.75-3.49,3.61
                  c-2.11,8.9,14.52,6.02,13.55,14.09c-0.3,2.5-3.34,2.8-5.29,3.19c-3.45,0.69-6.67,2.23-9.7,3.96c-1.57,0.91-3.55,1.77-3.32,3.83
                  c0.44,3.97,8.63,4.85,11.45,6.31c1.13,0.59,3.48,1.62,3.07,3.31c-1.75,7.23-11.77,5.69-16.9,8.27c-2.37,1.19-5.82,3.56-5.56,6.68
                  c0.29,3.6,10.02,5.95,12.95,7.47c1.11,0.57,3.57,2.65,3.21,4.15c-1.1,4.62-12.26,6.75-16.51,7.52c-2.86,0.51-7.54,1.72-7.59,5.43
                  c-0.03,1.78,2.6,2.69,3.83,3.32c3.14,1.62,6.67,2.45,10.05,3.49c1.11,0.34,3.56,1.18,3.83,2.56c0.45,2.25-2.65,3.61-4.23,4.11
                  c-2.39,0.76-4.95,0.79-7.39,1.34c-1.34,0.3-3.01,0.66-4.27,1.23c-4.09,1.86-13.51,11.23-7.72,14.93c1.6,1.02,3.75,0.49,5.54,0.38
                  c4.38-0.28,8.64,0.36,13.06-0.12c6.54-0.73,19.17,3.28,24.04-3.23c2.22-2.97,2.49-7.54,3.04-11.05
                  c1.41-8.96,1.92-13.56,3.53-22.47c0.81-4.48,4.26-15.51,13.21-15.32c9.16,0.2,11.28,9.2,9.74,16.2
                  c-2.24,10.14-12.18,34.25-7.05,34.45c11.06,0.46,23.88,0.77,26.34,0.27c5.16-1.05,5.17-18.43,6.79-23.63
                  c2.26-7.23,0.96-13.74,2.12-21.09c1.44-9.17,15.8-56.15,9.42-64.72c-1.83-2.45-14.35-0.48-16.56,0.23
                  c-7.94,2.52-14.48,14.68-15.1,20.52c-0.53,5.08-2.23,9.97-3.32,14.92c-0.68,3.06-4.26,11.03-10.95,10.62
                  c-5.48-0.34-10.04-6.92-9.04-11.35c1.47-6.52,3.17-13.72,4.65-20.02c23.98-3.37,66.43-52.18,82.92-83.9
                  c19.96,4.02,47.42,4.95,62.4,4.95c15.06,0,22.74-7.67,22.74-7.67c20.18-15.58,46.75-68.23,48.54-72.32
                  c0.52-1.02,1.02-1.54,1.54-1.54c1.54,0,3.33,3.58,3.33,3.58c4.37,84.43,28.6,144.78,84.39,150.7c-2.97,4.22-4.11,9.05-5.07,12.12
                  c-2.68,8.58-11.12,3.14-13.08,11.06c-2.03,8.2,15.57,9.68,13.73,15.78c-1.87,6.18-6.74,1.44-8.7,7.64
                  c-2.78,8.79,20.15,6.51,19.62,11.57c-0.63,5.93-7.88,2.38-8.31,8.73c-0.33,4.91,15.95,4.74,12.65,10.85
                  c-1.67,3.09-6.71,3.29-13.27,2.72c-5.42-0.47-4.82,9.08-12.63,7.92c-5.1-0.76-4.32-2.85-0.83-12.59
                  c3.01-8.43-4.92-14.15-20.49-14.21c-10.27-0.04-17.16,0.62-20.28,9.91c-3.25,9.71,22.7,5.72,21.96,11.84
                  c-0.75,6.16-9.52,2.16-10.89,10.55c-0.92,5.58,6.54,8.07,10.86,8.07c12.96-0.01,17.94-0.53,37.67-0.16
                  c7.85,0.15,9.86-9.8,18.68-9.61c5.78,0.12,13.28-4.1,15.22-11.89c2.91-11.73-17.45-10.51-16.49-16.36
                  c0.87-5.31,10.75-3.67,11.89-11.35c1.38-9.27-20.55-4.99-18.59-11.6c1.57-5.28,6.99-1.44,8.82-8.01c1.86-6.66-20.7-4-18.92-10.05
                  c1.63-5.53,5.29-3.71,9.45-7.36c5.76-5.06,2.89-11.35,7.8-11.57c9.5-0.43-2.89,15.92,8.49,16.3c11.43,0.38-3.3,17.94,7.64,20.76
                  c10.53,2.71,12.59-8.75,13.93-15.93c1.3-6.96-0.89-12.25-7.63-13.8c-5.5-1.27-1.81-9.48-7.26-14.91
                  c32.47-9.71,58.6-34.43,69.53-50.12c2.55-0.17,5.1-0.4,7.65-0.69c1.55-0.17,3.11-0.4,4.68-0.62c3.09-0.47,6.18-1.06,9.25-1.73
                  c4.79-1,9.61-2.31,14.51-3.83c0.24-0.08,0.48-0.15,0.72-0.23c11.02-3.49,22.19-8.36,33.4-14.82c2.96-1.69,5.83-3.42,8.58-5.21
                  c2.72-1.78,5.41-3.66,8.1-5.63c2.03-1.51,3.97-3.02,5.82-4.55c15.02-11.85,29.64-26.51,43.01-45.24c0-1.54-1.52-3.84-3.58-3.84
                  c-1.52,0-2.81,1.03-2.81,1.03c-14.2,16.5-32.19,24.39-46.45,24.49c-16.56-0.05-28.87-7.09-31.78-10.7
                  c-1.79-2.22-3.28-4.09-4.6-8.18c-0.73-2.26-0.8-4.04-0.77-5.11c0.48-16.33,29.61-32.81,49.31-37.06
                  c9.47-2.04,14.57-2.81,14.57-15.08c0-6.65-2.04-16.35-8.68-16.35c-38.33,0-52.39-10.22-52.39-20.18
                  c0-8.95,11.76-17.63,27.34-17.63c25.05,0,43.97,20.44,72.58,20.44c23.26,0,60.57-61.84,60.57-75.14
                  C1605.84,247.24,1602.76,245.19,1598.43,245.19z M803.47,450.66c-1.54,1.35-2.89,2.55-4.23,3.75c-2.09,0.68-4.1,1.16-5.9,1.16
                  c-12.53,0-17.9-6.65-17.9-17.39c0-22.25,23.78-62.4,56.26-96.42c0.06-0.06,0.1-0.09,0.16-0.15c3.01,0.77,6.4,1.2,9.95,1.2
                  c17.87,0,78.19-5.62,84.83-5.62c1.79,0,2.56,0.51,2.56,1.28C907.98,360.44,831.08,426.63,803.47,450.66z M972.9,383.7
                  c-1.29,0-2.04-0.76-2.81-2.3c-9.97-20.69-27.86-17.63-27.86-21.21c0-0.51,0.25-1.02,0.77-1.78c9.97-12.53,5.37-26.07,20.18-26.07
                  c11.51,0,37.06,0.26,37.06,15.07C1000.24,362.23,982.87,383.7,972.9,383.7z M1047.47,454.36c-2.29,1.53-3.06,3.83-3.06,5.62
                  c0,0.88,0.25,1.71,0.62,2.51c-24.02-1.76-54.22-42.23-64.97-64.48c0,0,0-1.54,2.29-2.3c38.61-7.63,75-31.02,96.47-56.62
                  c43.61-17.34,81.17-43.68,93.35-43.68c2.06,0,3.08,1.02,3.08,3.07C1175.25,338.35,1047.47,454.36,1047.47,454.36z"/>
              </g>
            </g>
          </g>
          <g>
            <path class="st0" d="M910.23,692v5.29h-8.44v25.36h-6.22v-25.36h-8.44V692H910.23z"/>
            <path class="st0" d="M926.3,723.05c-6.88,0-11.73-4.17-11.73-13.55v-4.35c0-9.46,4.84-13.55,11.73-13.55
              c6.93,0,11.77,4.09,11.77,13.55v4.35C938.07,718.88,933.23,723.05,926.3,723.05z M920.79,709.51c0,5.86,1.91,8.31,5.51,8.31
              c3.6,0,5.55-2.44,5.55-8.31v-4.35c0-5.86-1.95-8.31-5.55-8.31c-3.6,0-5.51,2.44-5.51,8.31V709.51z"/>
            <path class="st0" d="M963.08,722.65l-7.82-12.93l-3.64,4.8v8.13h-6.22V692h6.22v13.24L961.3,692h7.37l-9.37,12.39l11.19,18.26
              H963.08z"/>
            <path class="st0" d="M979.9,692l5.46,11.42l5.51-11.42h7.15l-9.55,18.12v12.53h-6.22v-12.53L972.71,692H979.9z"/>
            <path class="st0" d="M1013.48,723.05c-6.88,0-11.73-4.17-11.73-13.55v-4.35c0-9.46,4.84-13.55,11.73-13.55
              c6.93,0,11.77,4.09,11.77,13.55v4.35C1025.25,718.88,1020.41,723.05,1013.48,723.05z M1007.97,709.51c0,5.86,1.91,8.31,5.51,8.31
              c3.6,0,5.55-2.44,5.55-8.31v-4.35c0-5.86-1.95-8.31-5.55-8.31c-3.6,0-5.51,2.44-5.51,8.31V709.51z"/>
          </g>
          <g>
            <path class="st0" d="M714.09,792.51c-0.58-2.89-2.27-4.66-5.29-4.66c-3.6,0-5.51,2.44-5.51,8.31v4.35c0,5.69,1.91,8.31,5.51,8.31
              c3.11,0,4.62-1.55,5.29-4.66H720c-1.02,6.88-4.89,9.91-11.19,9.91c-6.88,0-11.73-4.17-11.73-13.55v-4.35
              c0-9.46,4.84-13.55,11.73-13.55c6.09,0,10.22,3.2,11.24,9.91H714.09z"/>
            <path class="st0" d="M737.09,814.05c-6.88,0-11.73-4.17-11.73-13.55v-4.35c0-9.46,4.84-13.55,11.73-13.55
              c6.93,0,11.77,4.09,11.77,13.55v4.35C748.86,809.88,744.02,814.05,737.09,814.05z M731.59,800.51c0,5.86,1.91,8.31,5.51,8.31
              c3.6,0,5.55-2.44,5.55-8.31v-4.35c0-5.86-1.95-8.31-5.55-8.31c-3.6,0-5.51,2.44-5.51,8.31V800.51z"/>
            <path class="st0" d="M779.24,783v30.65h-5.2l-11.95-18.97v18.97h-5.91V783h5.15l11.99,19.23V783H779.24z"/>
            <path class="st0" d="M807.97,783v5.29h-8.44v25.36h-6.22v-25.36h-8.44V783H807.97z"/>
            <path class="st0" d="M832.67,783v5.29h-12.88v6.89h11.55v5.24h-11.55v8h12.88v5.24h-19.1V783H832.67z"/>
            <path class="st0" d="M853.63,799.71l8.53-16.7h5.91v30.65h-5.95v-19.01l-6.66,13.5h-3.78l-6.57-13.37v18.88h-5.91V783h5.82
              L853.63,799.71z"/>
            <path class="st0" d="M882.54,802.86v10.79h-6.22V783h10.97c7.06,0,11.46,3.24,11.46,9.82c0,6.49-4.35,10.04-11.46,10.04H882.54z
               M886.4,797.62c4.31,0,6.13-1.51,6.13-4.8c0-2.93-1.82-4.53-6.13-4.53h-3.86v9.33H886.4z"/>
            <path class="st0" d="M915.54,814.05c-6.88,0-11.73-4.17-11.73-13.55v-4.35c0-9.46,4.84-13.55,11.73-13.55
              c6.93,0,11.77,4.09,11.77,13.55v4.35C927.31,809.88,922.46,814.05,915.54,814.05z M910.03,800.51c0,5.86,1.91,8.31,5.51,8.31
              c3.6,0,5.55-2.44,5.55-8.31v-4.35c0-5.86-1.95-8.31-5.55-8.31c-3.6,0-5.51,2.44-5.51,8.31V800.51z"/>
            <path class="st0" d="M951.33,813.65l-5.51-12.04h-4.97v12.04h-6.22V783h10.97c7.06,0,11.46,3.15,11.46,9.37
              c0,4.13-1.91,6.84-5.24,8.22l6.35,13.06H951.33z M945.02,796.38c3.69,0,5.82-0.8,5.82-4s-2.13-4.09-5.82-4.09h-4.18v8.08H945.02z"
              />
            <path class="st0" d="M961.36,813.65L972.02,783h5.95l10.66,30.65h-6.62l-1.87-5.82h-10.26l-1.87,5.82H961.36z M971.54,802.59h6.93
              L975,791.8L971.54,802.59z"/>
            <path class="st0" d="M1010.44,813.65l-5.51-12.04h-4.97v12.04h-6.22V783h10.97c7.06,0,11.46,3.15,11.46,9.37
              c0,4.13-1.91,6.84-5.24,8.22l6.35,13.06H1010.44z M1004.13,796.38c3.69,0,5.82-0.8,5.82-4s-2.13-4.09-5.82-4.09h-4.17v8.08H1004.13
              z"/>
            <path class="st0" d="M1026.52,783l5.46,11.42l5.51-11.42h7.15l-9.55,18.12v12.53h-6.22v-12.53l-9.55-18.12H1026.52z"/>
            <path class="st0" d="M1079.59,792.51c-0.58-2.89-2.26-4.66-5.29-4.66c-3.6,0-5.51,2.44-5.51,8.31v4.35c0,5.69,1.91,8.31,5.51,8.31
              c3.11,0,4.62-1.55,5.29-4.66h5.91c-1.02,6.88-4.89,9.91-11.19,9.91c-6.88,0-11.73-4.17-11.73-13.55v-4.35
              c0-9.46,4.84-13.55,11.73-13.55c6.08,0,10.22,3.2,11.24,9.91H1079.59z"/>
            <path class="st0" d="M1108.81,813.65l-5.51-12.04h-4.97v12.04h-6.22V783h10.97c7.06,0,11.46,3.15,11.46,9.37
              c0,4.13-1.91,6.84-5.24,8.22l6.35,13.06H1108.81z M1102.5,796.38c3.69,0,5.82-0.8,5.82-4s-2.13-4.09-5.82-4.09h-4.17v8.08H1102.5z"
              />
            <path class="st0" d="M1118.84,813.65L1129.5,783h5.95l10.66,30.65h-6.62l-1.87-5.82h-10.26l-1.87,5.82H1118.84z M1129.02,802.59
              h6.93l-3.46-10.79L1129.02,802.59z"/>
            <path class="st0" d="M1170.32,783v5.29h-12.88v7.6h11.55v5.24h-11.55v12.53h-6.22V783H1170.32z"/>
            <path class="st0" d="M1197.01,783v5.29h-8.44v25.36h-6.22v-25.36h-8.44V783H1197.01z"/>
            <path class="st0" d="M1218.55,790.96c-1.33-2.09-2.93-3.11-6.04-3.11c-2.8,0-4.31,1.29-4.31,3.38c0,2.4,1.95,3.46,6.04,4.49
              c6.4,1.6,9.28,4,9.28,9.42c0,5.02-3.38,8.93-10.57,8.93c-5.38,0-9.11-1.47-11.82-5.02l4.75-3.29c1.64,2.04,3.51,3.06,6.66,3.06
              c3.55,0,4.75-1.55,4.75-3.42c0-2.13-1.29-3.33-6.26-4.62c-6.04-1.6-9.06-4.49-9.06-9.55c0-4.84,3.2-8.62,10.48-8.62
              c4.89,0,8.57,1.64,10.75,5.02L1218.55,790.96z"/>
            <path class="st0" d="M766.47,879.38v5.29h-12.88v7.6h11.55v5.24h-11.55v12.53h-6.22v-30.65H766.47z"/>
            <path class="st0" d="M796.27,879.38v18.21c0,9.06-4.97,12.84-11.77,12.84c-6.8,0-11.77-3.78-11.77-12.79v-18.26h6.22v18.26
              c0,5.24,1.73,7.55,5.55,7.55c3.82,0,5.55-2.31,5.55-7.55v-18.26H796.27z"/>
            <path class="st0" d="M824.96,879.38v5.29h-8.44v25.36h-6.22v-25.36h-8.44v-5.29H824.96z"/>
            <path class="st0" d="M854.1,879.38v18.21c0,9.06-4.97,12.84-11.77,12.84c-6.8,0-11.77-3.78-11.77-12.79v-18.26h6.22v18.26
              c0,5.24,1.73,7.55,5.55,7.55c3.82,0,5.55-2.31,5.55-7.55v-18.26H854.1z"/>
            <path class="st0" d="M879.05,910.03l-5.51-12.04h-4.97v12.04h-6.22v-30.65h10.97c7.06,0,11.46,3.15,11.46,9.37
              c0,4.13-1.91,6.84-5.24,8.22l6.35,13.06H879.05z M872.75,892.75c3.69,0,5.82-0.8,5.82-4s-2.13-4.09-5.82-4.09h-4.18v8.08H872.75z"
              />
            <path class="st0" d="M911.07,879.38v5.29h-12.88v6.88h11.55v5.24h-11.55v8h12.88v5.24h-19.1v-30.65H911.07z"/>
            <path class="st0" d="M937.14,891.55h10.62v-12.17h6.22v30.65h-6.22v-13.24h-10.62v13.24h-6.22v-30.65h6.22V891.55z"/>
            <path class="st0" d="M981.38,879.38v5.29h-12.88v6.88h11.55v5.24h-11.55v8h12.88v5.24h-19.1v-30.65H981.38z"/>
            <path class="st0" d="M994.56,910.03h-6.22v-30.65h6.22V910.03z"/>
            <path class="st0" d="M1019.97,910.03l-5.51-12.04h-4.97v12.04h-6.22v-30.65h10.97c7.06,0,11.46,3.15,11.46,9.37
              c0,4.13-1.91,6.84-5.24,8.22l6.35,13.06H1019.97z M1013.66,892.75c3.69,0,5.82-0.8,5.82-4s-2.13-4.09-5.82-4.09h-4.17v8.08H1013.66
              z"/>
            <path class="st0" d="M1039.11,904.79h12.88v5.24h-19.1v-30.65h6.22V904.79z"/>
            <path class="st0" d="M1067.13,910.43c-6.88,0-11.73-4.17-11.73-13.55v-4.35c0-9.46,4.84-13.55,11.73-13.55
              c6.93,0,11.77,4.09,11.77,13.55v4.35C1078.9,906.26,1074.06,910.43,1067.13,910.43z M1061.62,896.88c0,5.86,1.91,8.31,5.51,8.31
              c3.6,0,5.55-2.44,5.55-8.31v-4.35c0-5.86-1.95-8.31-5.55-8.31c-3.6,0-5.51,2.44-5.51,8.31V896.88z"/>
            <path class="st0" d="M1097.02,910.43c-6.88,0-11.73-4.17-11.73-13.55v-4.35c0-9.46,4.84-13.55,11.73-13.55
              c6.93,0,11.77,4.09,11.77,13.55v4.35C1108.79,906.26,1103.95,910.43,1097.02,910.43z M1091.51,896.88c0,5.86,1.91,8.31,5.51,8.31
              c3.6,0,5.55-2.44,5.55-8.31v-4.35c0-5.86-1.95-8.31-5.55-8.31c-3.6,0-5.51,2.44-5.51,8.31V896.88z"/>
            <path class="st0" d="M1130.55,896.08l8.53-16.7h5.91v30.65h-5.95v-19.01l-6.66,13.5h-3.78l-6.57-13.37v18.88h-5.91v-30.65h5.82
              L1130.55,896.08z"/>
            <path class="st0" d="M1169.19,887.33c-1.33-2.09-2.93-3.11-6.04-3.11c-2.8,0-4.31,1.29-4.31,3.38c0,2.4,1.95,3.46,6.04,4.49
              c6.4,1.6,9.28,4,9.28,9.42c0,5.02-3.38,8.93-10.57,8.93c-5.38,0-9.11-1.47-11.82-5.02l4.75-3.29c1.64,2.04,3.51,3.06,6.66,3.06
              c3.55,0,4.75-1.55,4.75-3.42c0-2.13-1.29-3.33-6.26-4.62c-6.04-1.6-9.06-4.49-9.06-9.55c0-4.84,3.2-8.62,10.48-8.62
              c4.89,0,8.57,1.64,10.75,5.02L1169.19,887.33z"/>
          </g>
          <g>
            <g>
              <rect x="955.67" y="928.5" class="st0" width="5.67" height="65"/>
            </g>
            <g>
              <g>
                <polygon class="st0" points="935.31,974.71 939.47,970.85 958.49,991.32 977.53,970.85 981.68,974.71 958.49,999.65 			"/>
              </g>
            </g>
          </g>
          <g>
            <path class="st0" d="M958.5,887.5"/>
          </g>
          <g>
            <path class="st0" d="M958.5,822.5"/>
          </g>
          <g>
            <rect x="955.67" y="733.5" class="st0" width="5.67" height="33"/>
          </g>
          <g>
            <rect x="955.67" y="829.5" class="st0" width="5.67" height="33"/>
          </g>
          </svg>

          <a href="https://www.instagram.com/templemarketbathhouse" rel='noopener noreferrer' target="_blank"><img id="ig-link" width="50px" height="auto" src={ig} alt="Instagram"/></a>

          </div>
        </div>

        <SmokeBase/>
        <BGVideoPlayer />
      </>

    );
  }
}
//export default withStyles(styles)(Home);



export default Home;
