import React, { useState } from 'react';
import { Link } from "react-router-dom";
import About from './components/About';
import './scss/header.scss';
import logo from './assets/tmbh-header-yellow.png';


function AboutBtn() {
  const [state, setState] = useState(false);
  const handleClick = (state) => {
    setState(state);
  };
  return (
    <>
      <ul>
        <li>
          <h2 className="menu-button" onClick={() => handleClick(prevState => !prevState)}>
        {state?(<span className="close">x</span>):('+')}</h2>
        </li>
      </ul>
        {state?(<About func="handleClick(prevState => !prevState)" />):('')}
    </>
  );
}

function Header() {
  return (
    <>
      <header>
        <div className='logo'>
          <ul>
          </ul>
        </div>
        <div className='about'>
            <AboutBtn />
        </div>
      </header>
    </>
  );
};

export default Header;
