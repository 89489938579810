import React, { Component } from 'react';
import Smoke from "smoke-effect";

class SmokeBase extends Component {

  constructor() {
    super();
    this.setting = {
      WIDTH: window.innerWidth,
      HEIGHT: window.innerHeight,
      R: 255,
      G: 220,
      B: 236,
      MAXSMOKE: 10,
      TIMER: 8000
    };
    this.canvas = null;
    this.canvasCover = null;
    this.canvasContext = null;
    this.smoke = null;
    this.timer = null;
    this.timerArray = [];
  }

  componentDidMount() {
    this.canvas = document.getElementById("smoke");
    this.canvasContext = this.canvas.getContext("2d");
    this.canvasCover = document.getElementById("smoke-cover");
    this.canvas.width = this.setting.WIDTH;
    this.canvas.height = this.setting.HEIGHT;
    this.canvasCover.width = this.setting.WIDTH;
    this.canvasCover.height = this.setting.HEIGHT;
    this.smoke = new Smoke(this.canvasContext, [this.setting.R, this.setting.G, this.setting.B]); // context canvas, smoke color
    this.smoke.start();
    this.smoke.step(300);
    this.update();
  }

  update() {
    let t = Math.floor(Math.random()*this.setting.TIMER);
    for (let i = 1; i <= this.setting.MAXSMOKE; i++) {
      let x = Math.floor(Math.random()*window.innerWidth);
      let y = Math.floor(Math.random()*window.innerHeight);

      this.smoke.addSmoke(x, y, i);
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.update();
      }, t);
      clearTimeout(this.timerArray[i]);
      this.timerArray[i-1] = this.timer;
    }

  }


  render() {
    return (
        <>
          <canvas id="smoke"></canvas>
          <div id="smoke-cover"></div>
          <div id="smoke-logobase">
            
          </div>
        </>
    );
  }
}
export default SmokeBase;
