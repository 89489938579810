import React from 'react';
import { Link } from "react-router-dom";
import './scss/index.scss';

function NoMatch() {
  return(
    <>
      <h1>NoMatch</h1>
      <div>
      </div>
    </>
  );
};

export default NoMatch;