import {
  BrowserRouter as Router,
  Routes,
  Route
  } from "react-router-dom";
import logo from './logo.svg';
import './scss/App.scss';
import Contact from "./Contact";
import Home from "./Home";
import NoMatch from "./NoMatch";
import Footer from './Footer';
import Header from './Header';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
