import React, { Component } from "react";
import { Link } from "react-router-dom";

class about extends Component {
  constructor(props) {
    super();
    this.callBack = props.func;
  }
  render() {
    return (
      <>
        <div className="about-wrapper">
          <div className="about-links">
            <ul>
              <li>
              </li>
            </ul>
          </div>
          <div className="about-desc">
            <p>
              Temple Market Bathhouse™ is a Tokyo-based creative studio and community exploring contemporary craft and future heirlooms across Asia.
              <br />
              <br />
              Founded by the team behing Sankaku and Tokyo Keyboard.
            </p>
          </div>
        </div>
      </>
    );
  }
}
export default about;
